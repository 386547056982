import React from "react";

import Layout from "../layouts";
import SEO from "../components/seo";

import TeamSpiritSVG from "../images/team_spirit.svg";
import about from "../data/about.json";

import keywords from "../data/keywords.json";

function AboutPage() {
  return (
    <Layout>
      <SEO keywords={keywords} title="About" />
      <section className="bg-mph-light-gray">
        <div className="w-full flex flex-col items-center justify-center px-4 py-24 space-y-4">
          <div className="w-full max-w-6xl text-center space-y-4">
            <div className="font-bold text-3xl sm:text-6xl text-black tracking-wide space-y-2">
              <p>We help you make</p>
              <p>Better Product Decisions.</p>
            </div>
            <p className="text-base sm:text-xl text-gray-400 tracking-wider">
              Our mission is to help you <br />
              ideate, innovate and listen to your customers
              <br />
              to make better product decisions.
            </p>
          </div>
        </div>
      </section>
      <section className="text-black body-font w-full">
        <p className="text-2xl sm:text-4xl font-bold text-center w-full px-5 py-12">
          Principles we live by
        </p>
        <div className="container px-5 py-12 mx-auto flex flex-wrap w-full max-w-6xl">
          <div className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
            <img
              alt="feature"
              className="object-cover object-center h-full w-full"
              src={TeamSpiritSVG}
            />
          </div>
          <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
            {about.principles.map((item) => (
              <div
                className="flex flex-col mb-10 lg:items-start items-center"
                key={item.title}
              >
                <div className="flex-grow">
                  <h2 className="text-gray-900 text-lg title-font font-bold mb-3">
                    {item.title}
                  </h2>
                  <p className="leading-relaxed text-base">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default AboutPage;
